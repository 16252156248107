<template>
    <div>
            <el-row class="hidden-lg-and-up" :gutter="10">
                <el-col  :md="6">
                    <el-page-header @back="goBack" content="Configurations">
                    </el-page-header>
                </el-col>
                <el-col  :md="18" >
                    <el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" mode="horizontal">
                        <el-menu-item index="/configuration/paymentSystems" @click="goto" :router="true">
                            <i class="el-icon-s-finance"></i>
                            <span>Payment Gateway</span>
                        </el-menu-item>
                        <el-submenu index="/configuration">
                            <template slot="title">
                                <i class="el-icon-coin"></i>
                                <span>Storage</span>
                            </template>
                            <el-menu-item-group >
                                <el-menu-item index="/configuration/s3" @click="goto" :router="true" disabled>
                                    <i class=" el-icon-set-up"></i> <span>AWS S3 </span></el-menu-item>
                                <el-menu-item index="/configuration/share_point" @click="goto" :router="true"><i class=" el-icon-set-up"></i> <span>Share Point </span></el-menu-item>
                                <el-menu-item index="/configuration/gdc" @click="goto" :router="true" disabled> <i class=" el-icon-set-up"></i> <span>Google Drive </span></el-menu-item>
                                <el-menu-item index="/configuration/one_drive" @click="goto" :router="true" disabled><i class=" el-icon-set-up"></i> <span>One Drive</span></el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                        <el-menu-item index="/configuration/mail" @click="goto" :router="true">
                            <i class="el-icon-message"></i>
                            <span>Mail Configuration</span>
                        </el-menu-item>
                    </el-menu>
                    <div class="clearfix"></div>
                </el-col>
            </el-row>
            <el-row class="hidden-md-and-down" :gutter="10">
                <el-col :xs="24" :sm="24" :md="24">
                        <el-page-header @back="goBack" content="Configurations">
                        </el-page-header>
                        <hr />
                </el-col>
            </el-row>
            <el-row :gutter="10">

            <el-col :lg="4" class="hidden-md-and-down">
                <el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
                    <el-menu-item index="/configuration/paymentSystems" @click="goto" :router="true">
                        <i class="el-icon-s-finance"></i>
                        <span>Payment Gateway</span>
                    </el-menu-item>
                    <el-submenu index="/configuration">
                        <template slot="title">
                            <i class="el-icon-coin"></i>
                            <span>Storage</span>
                        </template>
                        <el-menu-item-group >
                            <el-menu-item index="/configuration/s3" @click="goto" :router="true" disabled>
                                <i class=" el-icon-set-up"></i> <span>AWS S3 </span></el-menu-item>
                            <el-menu-item index="/configuration/share_point" @click="goto" :router="true"><i class=" el-icon-set-up"></i> <span>Share Point </span></el-menu-item>
                            <el-menu-item index="/configuration/gdc" @click="goto" :router="true" disabled> <i class=" el-icon-set-up"></i> <span>Google Drive </span></el-menu-item>
                            <el-menu-item index="/configuration/one_drive" @click="goto" :router="true" disabled><i class=" el-icon-set-up"></i> <span>One Drive</span></el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-menu-item index="/configuration/mail" @click="goto" :router="true">
                        <i class="el-icon-message"></i>
                        <span>Mail Configuration</span>
                    </el-menu-item>
                </el-menu>
                
            </el-col>

            <el-col :md="24" :lg="20">
                <component :is="layout">
                    <router-view :layout.sync="layout" />
                </component>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DocumentsHelper from "@/mixins/CompanyDocumentsHelper";
export default {
    name: "ProfileBodySettings",
    data() {
        return {
            layout: "div",
        };
    },
    mixins: [DocumentsHelper],
    mounted() {
        this.getCompanyInformation();
    },
    computed: {
        ...mapGetters("auth", [
            "getUpdateProfilePicStatus",
            "getAuthenticatedUser",
            "getupdateprofilepicture",
        ]),
        getUserprofilepicture() {
            if (this.getupdateprofilepicture) {
                return this.getupdateprofilepicture;
            } else {
                return null;
            }
        },
        getClass() {
            return (routeName) => {
                if (routeName == this.$route.name) {
                    return "list-group-item list-group-item-action active";
                } else return "list-group-item list-group-item-action";
            };
        },
    },
    methods: {
        goBack() {
            this.$router.push({
                path: "/"
            })
        },
        goto(e) {
            console.log(e)
            if(e.index)
            {
                this.$router.push({
                path: e.index
            })
            }
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        }
    }
};
</script>

<style lang="scss" scoped>
.profile-page {
    .profile-card {
        border-radius: 4px;
        border: 1px solid #ebeef5;
        margin: 0 -1px;

        .list-group {
            .list-group-item {
                border-radius: 0;
                padding: 1em;
                border: none;
                color: #9299b8;

                [class^="el-icon"] {
                    margin-right: 10px;
                }

                &.active {
                    color: #f754a2;
                    border-left: 2px solid #f754a2;
                }
            }
        }
    }
}
.clearfix {
    padding-bottom: 2rem;
}
</style>